import React, { useEffect, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import axios from "axios";
import { Link } from "react-router-dom";

export const FeaturedBlog = () => {
  const [article, setArticle] = useState(null);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchFeaturedArticle = async () => {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/api/articles?populate[Image][fields][0]=url&pagination[page]=1&pagination[pageSize]=1&filters[isFeatured]=true`,
        headers: {},
      };

      try {
        const response = await axios.request(config);
        const articles = response.data.data;

        if (Array.isArray(articles) && articles.length > 0) {
          setArticle(articles[0]);
        } else {
          console.error("No featured articles found.");
        }
      } catch (error) {
        console.error("Error fetching featured article:", error);
      }
    };

    fetchFeaturedArticle();
  }, []);

  return (
    <div className="w-full">
      <div className="sectionContainer max-w-[1700px] responsiveWidth">
        <div className="pt-[41px] pb-[75px] md:pt-[117px] md:pb-[84px]">
          <div
            className="relative flex flex-col lg:flex-row items-start gap-10 py-9 px-5 md:py-[38px] md:p-[20px] lg:p-[30px] rounded-[25px]"
            style={{
              background:
                "linear-gradient(71.36deg, #000000 31.72%, #666666 131.95%)",
            }}
          >
            {article && (
              <>
                <div className="w-full lg:w-[40%] flex flex-col items-center md:flex-col lg:items-start">
                  <img
                    // src={`${baseUrl}${article.attributes.Image.data[0].attributes.url}`}
                    src={`${baseUrl}${article.Image?.[0]?.url}`}
                    alt="Featured Blog"
                    className="w-full h-[300px] md:h-[500px] min-h-[300px] rounded-[25px] object-cover"
                  />
                </div>
                <div className="w-full lg:w-[60%] flex justify-center">
                  <div className="flex flex-col gap-2">
                    <h1 className="font-sora text-left text-[35px] md:text-[45px] lg:text-[60px] font-semibold leading-[50.4px] md:leading-[65.52px] lg:leading-[75.6px]">
                      {article.title}
                    </h1>
                    <p>{article?.shortDescription}</p>
                    <Link to={`/blogDetail/${article?.id}`} className="pt-3">
                      <FiArrowUpRight
                        size={35}
                        className="p-1 border border-white rounded-full cursor-pointer"
                      />
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
