import React, { useState } from "react";

import { RiTwitterXFill } from "react-icons/ri";
import { FaLinkedinIn, FaFacebookF, FaPhoneAlt } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";

export const ContactUs = React.forwardRef((props, ref) => {
  const { homeStrapiData } = props;
  const [copied, setCopied] = useState(false);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  // Access contactus content from Strapi data
  const contactUsContent = homeStrapiData?.contactus || [];

  // Fallback data for social links
  const socialLinks = [
    {
      id: 1,
      platform: "LinkedIn",
      icon: <FaLinkedinIn className="text-white group-hover:text-[#0077B5]" />,
      url: contactUsContent?.linkedin_link || "#",
    },
    {
      id: 2,
      platform: "Twitter",
      icon: (
        <RiTwitterXFill className="text-white group-hover:text-[#1DA1F2]" />
      ),
      url: contactUsContent?.twitter_link || "#",
    },
    // {
    //   id: 3,
    //   platform: "Facebook",
    //   icon: <FaFacebookF className="text-white" />,
    //   url: contactUsContent?.facebook_link || "#",
    // },
    {
      id: 4,
      platform: "Email",
      icon: (
        <MdAlternateEmail className="text-white group-hover:text-[#DB4437]" />
      ),
      // url: `mailto:${contactUsContent?.email ?? ""}`,
      action: () => {
        navigator.clipboard.writeText(contactUsContent?.email ?? "");
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      },
    },
    {
      id: 5,
      platform: "Phone",
      icon: <FaPhoneAlt className="text-white group-hover:text-[#0F9D58]" />,
      url: `tel:${contactUsContent?.phone ?? ""}`,
    },
  ];

  // const [query, setQuery] = useState("");
  // const navigate = useNavigate();

  // const handleAskSena = () => {
  //   navigate("/chatbot");
  // };

  return (
    <div
      ref={ref}
      className="w-full border-t border-b border-white"
      style={{
        background: "linear-gradient(80deg, #666666 -60%, #000000 80%)",
      }}
      id="contactSection"
    >
      <div className="sectionContainer max-w-[1468px] responsiveWidth">
        <div className="flex flex-col-reverse md:flex-col lg:flex-row items-center gap-10 py-[47px] md:py-[80px]">
          <div className="w-full flex flex-col items-center md:items-start lg:w-[50%]">
            <h2 className="font-sora text-[40px] md:text-[52px] lg:text-[60px] font-semibold leading-[50.4px] md:leading-[65.52px] lg:leading-[75.6px] text-left mb-[20px] lg:mb-[45px]">
              Contact Us
            </h2>

            {/* <div className="flex flex-col items-center md:items-start gap-1">
              <div className="flex items-center gap-1">
                <IoLocationOutline />
                <h3 className="font-sora text-[14px] font-[700] leading-[19px] text-left">
                  Office Address:
                </h3>
              </div>
              <p className="font-sora text-center md:text-left text-[12px] md:text-[14px] font-normal leading-[19px]">
                {contactUsContent?.address || "No Address Available"}
              </p>
            </div> */}

            <div className="grid grid-cols-2 gap-3 mt-3 w-full ">
              {/* {socialLinks.map((link) => (
                <a
                  key={link.id}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="group w-full bg-[#00000080] p-3 md:px-[35px] md:py-[15px] font-sora text-[14px] font-normal leading-[19px] rounded-[25px] border border-white flex justify-center items-center gap-2">
                    {link.icon}
                    {link.platform}
                  </button>
                </a>
              ))} */}
              {socialLinks.map((link) => (
                <div key={link.id} className="relative">
                  {link.action ? (
                    <button
                      onClick={link.action}
                      className="group w-full bg-[#00000080] p-3 md:px-[35px] md:py-[15px] font-sora text-[14px] font-normal leading-[19px] rounded-[25px] border border-white flex justify-center items-center gap-2"
                    >
                      {link.icon}
                      {link.platform}
                    </button>
                  ) : (
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group w-full bg-[#00000080] p-3 md:px-[35px] md:py-[15px] font-sora text-[14px] font-normal leading-[19px] rounded-[25px] border border-white flex justify-center items-center gap-2"
                    >
                      {link.icon}
                      {link.platform}
                    </a>
                  )}
                  {/* Display "Copied to clipboard" */}
                  {copied && link.platform === "Email" && (
                    <span className="absolute bottom-[-30px] left-1/2 transform -translate-x-1/2 bg-black text-white text-[12px] px-2 py-1 rounded">
                      Copied to clipboard!
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full justify-center lg:w-[60%] flex lg:justify-end">
            <div className="flex flex-col items-center gap-5 md:items-end">
              <img
                src={`${baseUrl}${contactUsContent?.image?.url}`}
                alt="aboutUsImg"
                className="w-[70%] rounded-[25px] object-cover"
              />
              {/* <button
                onClick={handleAskSena}
                className="bg-[#F7D504] text-[16px] font-[600] p-3 md:px-[33px] md:py-[16px] rounded-[17px] text-[#1A1A1A] hover-shadow-yellow transition-shadow duration-300"
              >
                Ask Sena!
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
