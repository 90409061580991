import React, { useEffect, useState } from "react";
import axios from "axios";

import Header from "../components/atom/Header";
import { Footer } from "../components/atom/Footer";
import { InterviewProcess } from "../components/molecule/InterviewProcess";
import OpenRoles from "../components/molecule/OpenRoles";

export const Career = () => {
  const [careerData, setCareerData] = useState(null);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchCareerData = async () => {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/api/carrer?populate=*`,
        headers: {},
      };

      try {
        const response = await axios.request(config);
        console.log("Career Page Data:", response.data.data);
        setCareerData(response.data.data);
      } catch (error) {
        console.error("Error fetching career data:", error);
      }
    };

    fetchCareerData();
  }, []);

  return (
    <div className="appContainer">
      <Header />
      {careerData && (
        <>
          <div className="w-full">
            <div className="sectionContainer max-w-[1700px] responsiveWidth">
              <div className="pt-[40px] md:pt-[84px]">
                <div className="flex flex-col lg:flex-row gap-5 justify-between md:items-center">
                  <h2 className="font-sora text-left text-[44px] md:text-[52px] lg:text-[76px] font-semibold leading-[40.4px] md:leading-[45.52px] lg:leading-[55.44px]">
                    {careerData.Title || "Careers"}
                  </h2>
                  <p className="w-full md:w-[70vw] lg:w-[27vw] font-sora text-left md:text-center lg:text-left text-[12px] md:text-[16px] font-normal leading-[20.16px]">
                    {careerData.rightHeading}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Step-by-Step Guide */}
          {/* <InterviewProcess steps={careerData.StepByStepGuide} /> */}

          {/* Open Roles */}
          <OpenRoles roles={careerData.open_roles} />
        </>
      )}
      <Footer />
    </div>
  );
};
