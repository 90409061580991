import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";

import Logo from "../../assets/icon/senaLogo.svg";
import SenaBotRoboIconNew from "../../assets/icon/senaAiIcon.png";

const Header = ({
  servicesSectionRedirect,
  aboutUsSectionRedirect,
  contactUsSectionRedirect,
  blogSectionRedirect,
  careerSectionRedirect,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [mobileHeader, setMobileHeader] = useState(false);
  const [activeIcon, setActiveIcon] = useState("homeIcon");
  const [animatePage, setAnimatePage] = useState("");
  const sidebarRef = useRef(null);

  // Redirect to the blogs and career page
  const blogPageRedirect = () => {
    navigate("/blogs");
  };

  const careerPageRedirect = () => {
    navigate("/career");
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleChatBotClick = () => {
    setActiveIcon("bot");
    setAnimatePage("move-page-left");
    setTimeout(() => {
      navigate("/chatbot");
    }, 500);
  };

  // Toggle the sidebar visibility
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  /* mobile screen */
  useEffect(() => {
    const handleResize = () => {
      setMobileHeader(window.innerWidth <= 575);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Close the sidebar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };
    if (sidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  }, [sidebarOpen]);

  return (
    <>
      {mobileHeader ? (
        <div className={`w-full ${animatePage}`}>
          <div className="sectionContainer max-w-full">
            <div className="py-[0px]">
              <div className="flex items-center justify-between py-6 px-[15px] md:py-7 md:px-4 md:bg-black rounded-[25px] w-full">
                <div className="flex justify-start w-1/3">
                  <div className="flex justify-center gap-2 w-[56px] bg-black py-2 px-2 rounded-[25px]">
                    <div
                      className="flex items-center py-1 px-1 cursor-pointer rounded-[25px]"
                      style={{
                        backgroundColor:
                          activeIcon === "bot" ? "white" : "transparent",
                      }}
                      onClick={handleChatBotClick}
                      role="presentation"
                    >
                      <img
                        src={SenaBotRoboIconNew}
                        alt="senaBotRoboIcon"
                        className="w-[20px] h-auto invert"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-center w-1/3">
                  <img
                    src={Logo}
                    alt="logo"
                    className="w-[100px] h-auto md:px-5"
                  />
                </div>
                <div className="flex justify-end w-1/3">
                  <IoMenu
                    className="text-white text-[30px] cursor-pointer"
                    onClick={toggleSidebar}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full pt-4">
          <div className="sectionContainer max-w-full">
            <div className="py-[0px]">
              <div className="flex min-h-[50px] md:flex items-center justify-between py-7 px-4 mb-4 md:px-10 lg:px-20 bg-black rounded-[25px] w-full">
                <div onClick={handleLogoClick} role="presentation">
                  <img
                    src={Logo}
                    alt="logo"
                    className="w-[100px] md:w-[110px] h-auto cursor-pointer"
                  />
                </div>

                <ul className="flex justify-end text-nowrap space-x-5 lg:space-x-9 text-white">
                  {location.pathname === "/" && (
                    <>
                      <li
                        onClick={servicesSectionRedirect}
                        role="presentation"
                        className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                      >
                        Services
                      </li>
                      <li
                        onClick={aboutUsSectionRedirect}
                        role="presentation"
                        className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                      >
                        About Us
                      </li>
                      <li
                        onClick={contactUsSectionRedirect}
                        role="presentation"
                        className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                      >
                        Contact Us
                      </li>
                      <li
                        onClick={blogSectionRedirect}
                        role="presentation"
                        className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                      >
                        Blogs
                      </li>
                      <li
                        onClick={careerSectionRedirect}
                        role="presentation"
                        className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                      >
                        Careers
                      </li>
                    </>
                  )}

                  {location.pathname !== "/" && (
                    <>
                      <li
                        onClick={servicesSectionRedirect}
                        role="presentation"
                        className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                      >
                        <Link to="/#serviceSection">Services</Link>
                      </li>
                      <li
                        onClick={aboutUsSectionRedirect}
                        role="presentation"
                        className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                      >
                        <Link to="/#aboutSection">About Us</Link>
                      </li>
                      <li
                        onClick={contactUsSectionRedirect}
                        role="presentation"
                        className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                      >
                        <Link to="/#contactSection"> Contact Us</Link>
                      </li>
                      <li
                        onClick={blogPageRedirect}
                        role="presentation"
                        className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                      >
                        Blogs
                      </li>
                      <li
                        onClick={careerPageRedirect}
                        role="presentation"
                        className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                      >
                        Careers
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Sidebar */}
      {sidebarOpen && (
        <div className="fixed inset-0 z-[800] bg-black bg-opacity-75">
          <div
            ref={sidebarRef}
            className="absolute top-0 right-0 w-2/3 h-full text-white bg-[#2f2f2f] p-5 shadow-lg"
          >
            <IoClose
              className="text-white text-[30px] cursor-pointer"
              onClick={toggleSidebar}
            />
            <ul className="mt-10 space-y-5  text-white">
              {location.pathname === "/" && (
                <>
                  <li
                    onClick={() => {
                      servicesSectionRedirect();
                      setSidebarOpen(false);
                    }}
                    role="presentation"
                    className="font-sora text-[16px] cursor-pointer"
                  >
                    Services
                  </li>
                  <li
                    onClick={() => {
                      aboutUsSectionRedirect();
                      setSidebarOpen(false);
                    }}
                    role="presentation"
                    className="font-sora text-[16px] cursor-pointer"
                  >
                    About Us
                  </li>
                  <li
                    onClick={() => {
                      contactUsSectionRedirect();
                      setSidebarOpen(false);
                    }}
                    role="presentation"
                    className="font-sora text-[16px] cursor-pointer"
                  >
                    Contact Us
                  </li>
                  <li
                    onClick={() => {
                      blogSectionRedirect();
                      setSidebarOpen(false);
                    }}
                    role="presentation"
                    className="font-sora text-[16px] cursor-pointer"
                  >
                    Blogs
                  </li>
                  <li
                    onClick={() => {
                      careerSectionRedirect();
                      setSidebarOpen(false);
                    }}
                    role="presentation"
                    className="font-sora text-[16px] cursor-pointer"
                  >
                    Careers
                  </li>
                </>
              )}

              {location.pathname !== "/" && (
                <>
                  <li
                    onClick={blogPageRedirect}
                    role="presentation"
                    className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                  >
                    Blogs
                  </li>
                  <li
                    onClick={careerPageRedirect}
                    role="presentation"
                    className="font-sora font-normal text-[14px] leading-[18.9px] lg:text-[15px] cursor-pointer"
                  >
                    Careers
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
