import { data } from "autoprefixer";
import apiSlice from "../../app/api/apiSlice";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/jwt/login",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(credentials),
      }),
    }),
    signUp: builder.mutation({
      query: (userDetails) => ({
        url: "/auth/register",
        method: "POST",
        body: userDetails,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: email,
      }),
    }),
    resetPassword: builder.mutation({
      query: (email) => ({
        url: "/auth/reset-password",
        method: "POST",
        body: email,
      }),
    }),
    resendOtp: builder.mutation({
      query: (bodyDetails) => ({
        url: "app/v1/auth/sign-up/resend-otp",
        method: "POST",
        body: bodyDetails,
      }),
    }),
    jobSubmissions: builder.mutation({
      query: (bodyDetails) => ({
        url: `${baseUrl}/api/job-submissions`,
        method: "POST",
        body: { data: bodyDetails },
      }),
      // Optional: Tags to track cache invalidation
      // providesTags: [{ type: 'JobSubmission', id: 'LIST' }],
    }),

    // Send Email mutation
    sendEmail: builder.mutation({
      query: ({ id, bodyDetails }) => ({
        url: `${baseUrl}/api/send-email/${id}`,
        method: "GET",
        params: { data: bodyDetails },
      }),
    }),

    googleLogin: builder.query({
      query: () => ({
        url: "/connect/google",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useGoogleLoginQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useJobSubmissionsMutation,
  useSendEmailMutation,
  useResendOtpMutation,
} = authApiSlice;
