import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const OpenRoles = ({ roles }) => {
  const navigate = useNavigate();

  const handleRoleClick = (role) => {
    if (role.id) {
      navigate(`/JobDetails/${role.id}`, {
        state: {
          jobDescription: role.job_description,
          jobPosition: role.title,
        },
      });
    }
  };

  return (
    <div className="w-full">
      <div className="sectionContainer max-w-[1700px] responsiveWidth">
        <div className=" pt-[41px] pb-[75px] md:pt-[54px] md:pb-[46px]">
          <div className=" flex flex-col gap-5 border-t border-white">
            <div className="flex items-center justify-between pt-[30px] sm:pt-[50px]">
              <h1 className="font-sora text-left text-[28px] md:text-[44px] font-semibold leading-[40.4px] md:leading-[45.52px] lg:leading-[55.44px]">
                Open roles
              </h1>
              {/* <div className=" bg-[#00000080] p-3 md:px-[108px] md:py-[15px] font-sora text-[14px] font-normal leading-[19px] rounded-[25px] border border-white flex items-center justify-between">
                Email your CV
              </div> */}
            </div>

            <div className="relative flex flex-col lg:flex-row items-start">
              {/* <div className="w-full md:w-[100%] lg:w-[50%] flex flex-col md:flex-col">
                <h2 className="font-sora text-left text-[16px] md:text-[32px] font-[300] leading-[40.4px] md:leading-[45.52px] lg:leading-[55.44px]">
                  Engineering & Research
                </h2>
              </div> */}
              <div className="w-full md:w-[100%]">
                <div className="w-full lg:w-auto flex flex-col gap-3 mt-3">
                  {roles.map((role) => (
                    <div
                      key={role.id}
                      className="cursor-pointer bg-[#00000080] w-full lg:w-[500px] p-3 md:px-[35px] md:py-[15px] font-sora text-[14px] font-normal leading-[19px] rounded-[25px] border border-white flex items-center justify-between"
                      onClick={() => handleRoleClick(role)}
                    >
                      {role.title || "No title"}
                      <div className="p-1 bg-[#F7D504] text-black border border-white rounded-full">
                        <FiArrowUpRight size={20} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenRoles;
