import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import {
  useJobSubmissionsMutation,
  useSendEmailMutation,
} from "../features/auth/authApiSlice";
import Header from "../components/atom/Header";
import { Footer } from "../components/atom/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

// Validation Schema
const jobApplicationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Name is too short!")
    .max(50, "Name is too long!")
    .required("First name is required"),
  last_name: Yup.string()
    .min(2, "Name is too short!")
    .max(50, "Name is too long!")
    .required("Last name is required"),

  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
  resume: Yup.mixed()
    .required("Resume is required")
    .test(
      "fileFormat",
      "Unsupported format. Please upload PDF or DOC files only.",
      (value) =>
        value && ["application/pdf", "application/msword"].includes(value.type)
    ),
  cover_letter: Yup.mixed()
    .nullable() // Allows cover_letter to be null
    .test(
      "fileFormat",
      "Unsupported format. Please upload PDF or DOC files only.",
      (value) =>
        !value || ["application/pdf", "application/msword"].includes(value.type)
    ),
  linkedIn_url: Yup.string().url("Invalid LinkedIn URL"),
});

export const JobDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { jobDescription, jobPosition } = location.state || {};
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [jobSubmissions] = useJobSubmissionsMutation();
  const [sendEmail] = useSendEmailMutation();

  const uploadFile = async (file, field, refId) => {
    const data = new FormData();
    data.append("field", field);
    data.append("refId", refId); // Set refId from job submission ID
    data.append("ref", "api::job-submission.job-submission");
    data.append("files", file);

    const config = {
      method: "post",
      url: `${baseUrl}/api/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      console.log("File upload response:", response.data); // Debugging log
      return response.data;
    } catch (error) {
      console.error(
        "File upload error:",
        error.response?.data || error.message
      );
      throw error;
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      resume: null,
      cover_letter: null,
      linkedIn_url: "",
    },
    validationSchema: jobApplicationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Trigger popup only if there are no validation errors
      if (Object.keys(formik.errors).length === 0) {
        setIsPopupVisible(true);
      }
      setSubmitting(false);
    },
  });

  const handleSend = async () => {
    try {
      // Include the job position in the payload
      const submissionPayload = {
        ...formik.values,
        job_position: jobPosition, // Add the job position here
      };

      const {
        data: { id },
      } = await jobSubmissions(submissionPayload).unwrap();

      // Step 1: Upload resume and cover letter with the captured refId (submission ID)
      if (formik.values.resume) {
        await uploadFile(formik.values.resume, "resume", id);
      }
      if (formik.values.cover_letter) {
        await uploadFile(formik.values.cover_letter, "cover_letter", id);
      }

      // Step 2: Send email after submission
      const sendEmailResponse = await sendEmail({ id });
      if (sendEmailResponse?.data?.status === 200) {
        toast.success("Application submitted and email sent successfully!");
      } else {
        toast.error("Application submitted, but failed to send email.");
      }

      // Step 3 : Reset form and close popup
      formik.resetForm();
      setIsPopupVisible(false);
      // toast.success("Application submitted successfully");
      navigate("/");
    } catch (error) {
      console.error("Submission error:", error);
      toast.error(error?.data?.detail ?? "Failed to submit application");
    }
  };

  const handleEditForm = () => {
    setIsPopupVisible(false);
  };

  return (
    <div className="appContainer">
      <Header />
      <h1 className="text-center text-[40px] font-sora">Job Details</h1>

      <div className="htmlContent w-full flex flex-col justify-center px-5 sm:px-[12%] xl:px-[15%] py-6">
        <div
          dangerouslySetInnerHTML={{
            __html: jobDescription || "Content not available",
          }}
        />
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className="sm:max-w-[70%] xl:max-w-[40%] mx-auto p-4 space-y-4"
        noValidate
      >
        <div className="w-full flex flex-col">
          <div className="flex gap-5 font-sora">
            <div className="w-full">
              <label htmlFor="first_name" className="block mb-1 font-medium">
                First Name *
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full text-[#1A1A1A] p-2 border border-gray-300 rounded"
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <p className="text-red-500 text-sm">
                  {formik.errors.first_name}
                </p>
              )}
            </div>

            <div className="w-full">
              <label htmlFor="last_name" className="block mb-1 font-medium">
                Last Name *
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full text-[#1A1A1A] p-2 border border-gray-300 rounded"
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <p className="text-red-500 text-sm">
                  {formik.errors.last_name}
                </p>
              )}
            </div>
          </div>

          <div className="w-full mt-4 font-sora">
            <label htmlFor="email" className="block mb-1 font-medium">
              Email *
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full text-[#1A1A1A] p-2 border border-gray-300 rounded"
            />
            {formik.touched.email && formik.errors.email && (
              <p className="text-red-500 text-sm">{formik.errors.email}</p>
            )}
          </div>

          <div className="w-full mt-4 font-sora">
            <label htmlFor="phone" className="block mb-1 font-medium">
              Phone *
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full text-[#1A1A1A] p-2 border border-gray-300 rounded"
            />
            {formik.touched.phone && formik.errors.phone && (
              <p className="text-red-500 text-sm">{formik.errors.phone}</p>
            )}
          </div>

          <div className="w-full mt-4 font-sora">
            <label htmlFor="resume" className="block mb-1 font-medium">
              Resume/CV *
            </label>
            <input
              type="file"
              id="resume"
              name="resume"
              onChange={(event) =>
                formik.setFieldValue("resume", event.currentTarget.files[0])
              }
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded"
            />
            {formik.touched.resume && formik.errors.resume && (
              <p className="text-red-500 text-sm">{formik.errors.resume}</p>
            )}
          </div>

          <div className="w-full mt-4 font-sora">
            <label htmlFor="cover_letter" className="block mb-1 font-medium">
              Cover Letter
            </label>
            <input
              type="file"
              id="cover_letter"
              name="cover_letter"
              onChange={(event) =>
                formik.setFieldValue(
                  "cover_letter",
                  event.currentTarget.files[0]
                )
              }
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded"
            />
            {formik.touched.cover_letter && formik.errors.cover_letter && (
              <p className="text-red-500 text-sm">
                {formik.errors.cover_letter}
              </p>
            )}
          </div>

          <div className="w-full mt-4 font-sora">
            <label htmlFor="linkedIn_url" className="block mb-1 font-medium">
              Linkedin Profile
            </label>
            <input
              type="url"
              id="linkedIn_url"
              name="linkedIn_url"
              value={formik.values.linkedIn_url}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full text-[#1A1A1A] p-2 border border-gray-300 rounded"
              placeholder="https://www.linkedin.com/in/username"
            />
            {formik.touched.linkedIn_url && formik.errors.linkedIn_url && (
              <p className="text-red-500 text-sm">
                {formik.errors.linkedIn_url}
              </p>
            )}
          </div>

          <button
            type="submit"
            className="w-full p-2 mt-4 !bg-[#F7D504] font-sora text-[16px] font-[600] !rounded-[17px] !text-[#1A1A1A] hover-shadow-yellow transition-shadow duration-300"
          >
            Submit Application
          </button>
        </div>
      </form>

      {isPopupVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full text-center">
            <h2 className="text-xl text-[#1A1A1A] font-bold mb-4 font-sora">
              Confirm Submission
            </h2>
            <p className="text-[#1A1A1A] text-sm font-medium font-sora">
              Are you sure you want to submit your application?
            </p>
            <div className="flex justify-around mt-4">
              <button
                onClick={handleEditForm}
                className="bg-gray-300 p-2 rounded-xl font-sora"
              >
                Edit Form
              </button>
              <button
                onClick={handleSend}
                className="bg-green-500 text-white p-2 rounded-xl font-sora"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default JobDetails;
