import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="w-full">
      <div className="sectionContainer max-w-[1468px] responsiveWidth">
        <div className="flex flex-col gap-5 md:flex-row items-center justify-between py-[45px]  md:pt-[80px]  lg:pt-[127px] md:pb-[30px] w-full">
          <div className=" flex w-full justify-center md:contents">
            <div className="text-center w-1/2 text-white md:w-1/3 space-x-5 md:space-x-8 font-sora font-normal text-[12px] md:text-[16px] lg:leading-[20.16px]">
              <Link to="/termsAndConditions" className="cursor-pointer">
                Terms and conditions
              </Link>
            </div>
            <div className="text-center w-1/2 text-white md:w-1/3 space-x-5 md:space-x-8 font-sora font-normal text-[12px] md:text-[16px] lg:leading-[20.16px]">
              <Link to="/PrivacyPolicy" className="cursor-pointer">
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className="text-center w-full text-white md:w-1/3 space-x-5 md:space-x-8 font-sora font-normal text-[12px] md:text-[16px] lg:leading-[20.16px]">
            <p className="cursor-pointer">© 2024 · Sena Ai</p>
          </div>
        </div>
      </div>
    </div>
  );
};
