import React, { useEffect } from "react";

import Header from "../components/atom/Header";
import { Footer } from "../components/atom/Footer";
import { FeaturedBlog } from "../components/molecule/FeaturedBlog";
import { BlogGrid } from "../components/molecule/BlogGrid";

const Blogs = () => {
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="appContainer">
      <Header />
      <div className="w-full">
        <div className="sectionContainer max-w-[1700px] responsiveWidth">
          <div className="pt-[40px] md:pt-[84px]">
            <div className="flex flex-col lg:flex-row gap-5 justify-between md:items-center">
              <h2 className="font-sora text-left text-[44px] md:text-[52px] lg:text-[76px] font-semibold leading-[40.4px] md:leading-[45.52px] lg:leading-[55.44px] ">
                Our Blog
              </h2>
            </div>
          </div>
        </div>
      </div>
      <FeaturedBlog />
      <BlogGrid />
      <Footer />
    </div>
  );
};

export default Blogs;
