import React from "react";
import { ReactTyped } from "react-typed";

const AboutUs = React.forwardRef((props, ref) => {
  const { ragAIData, homeStrapiData } = props;

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const aboutContent = homeStrapiData?.abouts || [];
  const aboutUsBoxes = aboutContent?.boxes || [];

  const aboutUsData = aboutUsBoxes.map((box) => ({
    title: box.heading,
    description: box.description,
  }));

  // Determine which section to display based on the ragAIData subsection
  let sectionsToDisplay = aboutUsData;

  if (ragAIData?.data?.subsection === "Mission Statement") {
    sectionsToDisplay = [
      {
        title: "Mission Statement",
        description: ragAIData.data.content.text,
      },
    ];
  } else if (ragAIData?.data?.subsection === "Vision") {
    sectionsToDisplay = [
      {
        title: "Vision",
        description: ragAIData.data.content.text,
      },
    ];
  } else if (
    ragAIData?.data?.subsection &&
    ragAIData?.data?.section === "About Us"
  ) {
    sectionsToDisplay = [
      {
        title: ragAIData?.data?.subsection,
        description: ragAIData.data.content.text,
      },
    ];
  }

  return (
    <div
      ref={ref}
      className="w-full border-b border-white"
      style={{
        background:
          "linear-gradient(71.36deg, #000000 31.72%, #666666 131.95%)",
      }}
      id="#aboutSection"
    >
      <div className="sectionContainer max-w-[1468px] responsiveWidth">
        <div className="flex flex-col lg:flex-row gap-[45px] md:gap-[80px] lg:gap-[100px] py-[47px] md:py-[80px] px-[7px] md:px-0">
          <div className="w-full lg:w-[50%]">
            <h2 className="pb-[45px] font-sora text-[40px] md:text-[52px] lg:text-[60px] font-semibold leading-[50.4px] md:leading-[65.52px] lg:leading-[65.6px] text-left">
              {aboutContent?.title}
            </h2>
            <div className="flex flex-col lg:flex-row">
              <div className="flex flex-col gap-[25px]">
                {sectionsToDisplay.map((section, index) => (
                  <div
                    key={index}
                    className="bg-[#00000080] p-10 md:px-[35px] md:py-[26px] rounded-[25px] border border-white w-full"
                  >
                    <h3 className="font-sora text-[20px] lg:text-[28px] font-semibold leading-[25.2px] lg:leading-[35.28px] text-left">
                      {section.title}
                    </h3>

                    {/* if new content is available */}
                    {ragAIData?.data?.content?.text ? (
                      <ReactTyped
                        key={ragAIData.data.content.text}
                        strings={[ragAIData.data.content.text]}
                        typeSpeed={1}
                        backSpeed={10}
                        showCursor={false}
                      />
                    ) : (
                      <p className="font-sora text-[12px] md:text-[16px] font-normal leading-[22.64px] md:leading-[26.64px] text-left mt-3">
                        {section.description}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="w-full lg:w-[50%]">
            <div className="relative">
              <img
                src={`${baseUrl}${aboutContent?.image?.url}`}
                alt="aboutUsImg"
                className="w-full rounded-[25px] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AboutUs;
