import React, { useEffect, useState } from "react";
import { Footer } from "../components/atom/Footer";
import Header from "../components/atom/Header";

const TermsAndConditions = () => {
  const [content, setContent] = useState(null);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/terms-condition`);

        const data = await response.json();
        setContent(data.data || null);
      } catch (error) {
        console.error("Error fetching Privacy Policy:", error);
      }
    };

    fetchData();
  }, []);

  const Description = content?.attributes?.description;

  return (
    <div className="appContainer">
      <Header />
      <div className="max-w-[80%] mx-auto p-4">
        <h1 className="text-2xl sm:text-3xl font-semibold mb-4">
          {content?.attributes?.heading}
        </h1>
        <div className="htmlContent">
          <div
            dangerouslySetInnerHTML={{
              __html: content?.description || "Content not available",
            }}
            style={{ whiteSpace: "pre-wrap" }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
