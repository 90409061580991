import React, { useEffect, useMemo, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const OurBlog = React.forwardRef((_, ref) => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchBlogs = (page) => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/articles?populate[Image][fields][0]=url&pagination[page]`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (Array.isArray(response.data.data)) {
          setBlogs(response.data.data);
        } else {
          console.error("Data is not an array:", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchBlogs(currentPage);
  }, [currentPage]);

  const handleBlogClick = (documentId) => {
    navigate(`/blogDetail/${documentId}`);
  };

  // Carousel responsiveness settings
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        <button
          className={currentSlide === 0 ? "disable" : ""}
          onClick={() => previous()}
        >
          <IoIosArrowBack className="text-black text-[40px] bg-white p-2 rounded-[8px] absolute top-[34%] -left-[5px] md:left-[0px] xl:left-[-60px]" />
        </button>
        <button onClick={() => next()}>
          <IoIosArrowForward className="text-black text-[40px] bg-white p-2 rounded-[8px] absolute top-[34%] -right-[5px] md:right-[0px] xl:right-[-60px]" />
        </button>
      </div>
    );
  };

  const ButtonGroup1 = () => (
    <div className="carousel-button-group">
      <button
        className="custom-prev-button"
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <IoIosArrowBack className="z-[99] text-black text-[40px] bg-white p-2 rounded-[8px] absolute top-[34%] -left-[5px] md:left-[0px] xl:left-[-60px]" />
      </button>
      <button
        className="custom-next-button"
        onClick={() => swiperRef.current?.slideNext()}
      >
        <IoIosArrowForward className="z-[99] text-black text-[40px] bg-white p-2 rounded-[8px] absolute top-[34%] -right-[5px] md:right-[0px] xl:right-[-60px]" />
      </button>
    </div>
  );

  // Function to create repeated slides
  const repeatedBlogs = useMemo(() => {
    const repeated = [...blogs, ...blogs, ...blogs]; // Repeat the slides 3 times
    return repeated;
  }, [blogs]);

  const blogRedirect = () => {
    navigate("/blogs");
  };

  return (
    <div
      ref={ref}
      className="w-full border-b border-white"
      style={{
        background:
          "linear-gradient(71.36deg, #000000 31.72%, #666666 131.95%)",
      }}
    >
      <div className="sectionContainer max-w-[1468px] responsiveWidth">
        <div className="w-full py-[80px] similarExpTopSec">
          <h2 className="font-sora text-[40px] md:text-[52px] lg:text-[60px] font-semibold leading-[50.4px] md:leading-[65.52px] lg:leading-[75.6px] text-left mb-[45px]">
            Our Blog
          </h2>
          {/* <div className="relative">
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              customTransition="all 0.5s ease"
              transitionDuration={1000}
              renderButtonGroupOutside={true}
              arrows={false}
              customButtonGroup={<ButtonGroup />}
              swipeable={true}
              draggable={true}
              keyBoardControl={true}
            >
              {blogs.map((blog, index) => {
                // const { attributes } = blog;
                // const imageUrl = attributes?.Image?.data?.[0]?.attributes?.url;
                // const contentText =
                //   attributes?.Content?.[0]?.children?.[0]?.text || "";

                const imageUrl = blog?.Image?.[0]?.url;
                const fullImageUrl = imageUrl ? `${baseUrl}${imageUrl}` : null;
                const blogTitle = blog?.title || "No Title";
                const shortDescription =
                  blog?.shortDescription || "No Description";

                return (
                  <div
                    key={blog.documentId}
                    onClick={() => handleBlogClick(blog.documentId)}
                  >
                    <div className="p-2 cursor-pointer">
                      {fullImageUrl && (
                        <img
                          src={fullImageUrl}
                          alt={`Blog Image ${index + 1}`}
                          className="w-full h-[250px] md:h-[175px] lg:h-[288px] rounded-[25px] object-cover"
                        />
                      )}
                    </div>
                    <p className="py-[5px] px-[15px] text-[13px] md:text-[16px]">
                      {blogTitle}
                    </p>
                  </div>
                );
              })}
            </Carousel>
          </div> */}

          <div className="relative">
            <Swiper
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              // responsive={responsive}
              slidesPerView={3} // Show 3 slides at a time
              spaceBetween={10} // Space between slides
              slideToClickedSlide={true} // Enable clicking a slide to navigate
              initialSlide={0} // Start with the first slide
              cssMode={true}
              modules={[FreeMode, Pagination, Autoplay, Navigation]} // Add Swiper modules
              className="mySwiper"
              loopAdditionalSlides={3} // Preload additional slides for smooth looping
              navigation={false} // Enable navigation arrows
              pagination={false}
              freeMode={true} // Allow free scrolling
              speed={600} // Set transition speed
              // autoplay={{
              //   delay: 2500, // Delay in ms
              //   disableOnInteraction: false, // Keep autoplay even if the user interacts
              // }}
              // loop={true}
              breakpoints={{
                0: {
                  slidesPerView: 1, // Show 1 slide for mobile devices
                  spaceBetween: 10, // Adjust spacing for mobile
                },
                768: {
                  slidesPerView: 2, // Show 2 slides for tablets
                  spaceBetween: 15, // Adjust spacing for tablets
                },
                1024: {
                  slidesPerView: 2, // Show 3 slides for desktops
                  spaceBetween: 20, // Adjust spacing for desktops
                },
                1280: {
                  slidesPerView: 3, // Show 3 slides for desktops
                  spaceBetween: 20, // Adjust spacing for desktops
                },
              }}
            >
              {/* {blogs.map((blog, index) => { */}
              {repeatedBlogs.map((blog, index) => {
                const imageUrl = blog?.Image?.[0]?.url;
                const fullImageUrl = imageUrl ? `${baseUrl}${imageUrl}` : null;
                const blogTitle = blog?.title || "No Title";

                return (
                  <>
                    <SwiperSlide key={blog.documentId}>
                      <div
                        onClick={() => handleBlogClick(blog.documentId)}
                        className="p-2 cursor-pointer"
                      >
                        {fullImageUrl && (
                          <img
                            src={fullImageUrl}
                            alt={`Blog Image ${index + 1}`}
                            className="w-full h-[250px] md:h-[175px] lg:h-[288px] rounded-[25px] object-cover"
                          />
                        )}
                        <p className="py-[5px] px-[15px] text-[13px] md:text-[16px]">
                          {blogTitle}
                        </p>
                      </div>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
            <ButtonGroup1 />
          </div>

          <div className="w-full flex justify-end md:pt-[40px] lg:pt-[60px]">
            <button
              onClick={blogRedirect}
              className="bg-[#F7D504] text-[16px] font-[600] p-3 md:px-[33px] md:py-[16px] rounded-[17px] text-[#1A1A1A] hover-shadow-yellow transition-shadow duration-300"
            >
              See all blogs
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default OurBlog;
