import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

import Header from "../components/atom/Header";
import { Footer } from "../components/atom/Footer";

const BlogDetail = () => {
  const [blogData, setBlogData] = useState(null);
  const { documentId } = useParams();
  console.log("🚀 ~ BlogDetail ~ documentId:", blogData);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (documentId) {
      // Fetch the blog details by documentId from Strapi
      const fetchBlogData = async () => {
        try {
          const response = await fetch(
            `${baseUrl}/api/articles/${documentId}?populate=*`
          );
          console.log("🚀 ~ fetchBlogData ~ response:", response.data);

          const data = await response.json();
          setBlogData(data?.data);
        } catch (error) {
          console.error("Error fetching blog data:", error);
        }
      };

      fetchBlogData();
    }
  }, [documentId]);

  if (!blogData) {
    return <p>Loading...</p>;
  }

  // const { Content, Image } = blogData;

  const { title, shortDescription, Content, Image } = blogData;

  return (
    <div className="appContainer">
      <Header />
      <div className="w-full">
        <div className="sectionContainer max-w-[1700px] responsiveWidth">
          <div className="flex flex-col gap-20">
            {/* Blog title */}
            <div className="pt-[40px] md:pt-[84px]">
              <div className="flex flex-col lg:flex-row gap-5 justify-between md:items-center">
                <h2 className="font-sora text-left text-[44px] md:text-[52px] lg:text-[76px] font-semibold leading-[41.4px] md:leading-[55.52px] lg:leading-[65.44px] ">
                  {title || "Blog Details"}
                </h2>
              </div>
            </div>

            <div className="flex flex-col gap-5">
              {/* Blog image */}
              {Image ? (
                <img
                  src={`${baseUrl}${Image[0]?.url}`}
                  alt="Blog Image"
                  className="w-full h-[500px] rounded-[25px] object-cover"
                />
              ) : (
                <p>No image available</p>
              )}

              {/* Blog content rendering */}
              <div className="htmlContent mt-4 px-5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: Content || "Content not available",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetail;
